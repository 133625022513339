import {
  TokensResponse,
  A12nStoreState,
  A12nAction,
  A12nStoreOpts,
} from "./types"

import {
  saveToken,
  clearTokens,
  extractTokenInfo,
  getToken,
  isTokenExpired,
} from "./utils"

const INITIAL_STATE = {
  user: undefined,
  error: null,
  pending: false,
  doneAt: 0,
  meta: undefined,
}

type ListenerFn<T> = (state: A12nStoreState<T>) => void

function reducer<T>(
  s: A12nStoreState<T>,
  action: A12nAction<T>
): A12nStoreState<T> {
  switch (action.type) {
    case "INIT": {
      return { ...s }
    }
    case "INVALID": {
      return { ...s, user: null, error: "invalidTokenError" }
    }
    case "SET_USER": {
      const { user } = action.payload
      return { ...s, user }
    }
    case "PENDING": {
      const { payload } = action
      return { ...s, pending: payload }
    }
    case "SET_TOKENS": {
      const { user, meta } = action.payload
      return {
        ...s,
        pending: false,
        doneAt: Date.now(),
        error: null,
        //
        user,
        meta,
      }
    }
    case "GET_TOKENS_ERROR": {
      const { payload } = action
      return {
        ...s,
        pending: false,
        doneAt: Date.now(),
        error: payload,
        user: null,
      }
    }

    default: {
      console.error(`a12nStore: action found no match:`, action)
      return s
    }
  }
}

class A12nStore<T> {
  private listeners: Set<ListenerFn<T>>
  private dispatch: (action: A12nAction<T>) => void
  //
  private __refreshToken?: string
  private __accessToken?: string
  state: A12nStoreState<T>

  refreshTokensPromise: (refreshToken: string) => Promise<TokensResponse>
  getTokensPromise: (values: any) => Promise<TokensResponse>

  constructor(opts: A12nStoreOpts) {
    this.refreshTokensPromise = opts.refreshTokens
    this.getTokensPromise = opts.getTokens
    this.listeners = new Set()
    //

    //
    let refreshToken = getToken("refreshToken")
    this.__refreshToken = isTokenExpired(refreshToken)
      ? undefined
      : refreshToken

    let _accessToken = getToken("accessToken")
    this.__accessToken = isTokenExpired(_accessToken) ? undefined : _accessToken
    //
    const user = extractTokenInfo<T>(_accessToken)
    //

    this.state = reducer<T>(INITIAL_STATE, { type: "INIT" })

    this.dispatch = a => {
      const _state = reducer<T>(this.state, a)
      this.state = _state
      this.listeners.forEach(l => l(_state))
    }

    if (user === null) {
      this.dispatch({ type: "INVALID" })
    } else {
      this.dispatch({ type: "SET_USER", payload: { user } })
    }
  }

  onAuthStateChanged = (listener: ListenerFn<T>) => {
    this.listeners.add(listener)
    listener(this.state)
    return () => {
      this.listeners.delete(listener)
    }
  }

  get refreshToken(): string | undefined {
    let tkn = this.__refreshToken
    return isTokenExpired(tkn) ? undefined : tkn
  }
  get accessToken(): string | undefined {
    let tkn = this.__accessToken
    return isTokenExpired(tkn) ? undefined : tkn
  }

  private storeTokens = ({ accessToken, refreshToken }: TokensResponse) => {
    this.__accessToken = accessToken
    saveToken("accessToken", accessToken)
    if (refreshToken) {
      this.__refreshToken = refreshToken
      saveToken("refreshToken", refreshToken)
    }
  }

  private gtfo = () => {
    clearTokens()
    this.__refreshToken = undefined
    this.__accessToken = undefined
    this.dispatch({ type: "INVALID" })
    return null
  }

  logout = this.gtfo
  on401 = this.gtfo
  on403 = this.gtfo

  private refreshTokensAndReturnAccessToken = (refreshToken: string) => {
    return this.refreshTokensPromise(refreshToken)
      .then((tokensResponse: TokensResponse) => {
        const { accessToken, meta } = tokensResponse
        const user = extractTokenInfo<T>(accessToken)
        if (user === null) {
          this.dispatch({ type: "INVALID" })
        } else {
          this.dispatch({
            type: "SET_TOKENS",
            payload: {
              user,
              meta,
            },
          })
        }
        this.storeTokens(tokensResponse)
        //

        return accessToken
      })
      .catch(errors => {
        console.log("refreshTokensAndReturnAccessToken::errors", errors)
        this.dispatch({
          type: "GET_TOKENS_ERROR",
          payload: "refreshTokensError",
        })
        return Promise.reject(errors)
      })
  }

  // public

  login = (values: any) => {
    return this.getTokensPromise(values)
      .then((tokensResponse: TokensResponse) => {
        const { accessToken, meta } = tokensResponse
        const user = extractTokenInfo<T>(accessToken)
        if (user === null) {
          this.dispatch({ type: "INVALID" })
        } else {
          this.dispatch({
            type: "SET_TOKENS",
            payload: { user, meta },
          })

          this.storeTokens(tokensResponse)

          return user
        }
      })
      .catch(error => {
        this.dispatch({ type: "GET_TOKENS_ERROR", payload: "getTokensError" })
        return Promise.reject(error)
      })
  }

  getAccessToken = () => {
    if (!!this.accessToken) {
      return Promise.resolve(this.accessToken)
    } else if (!!this.refreshToken) {
      return this.refreshTokensAndReturnAccessToken(this.refreshToken)
    } else {
      this.gtfo()
      return Promise.reject()
    }
  }
}

export default A12nStore
