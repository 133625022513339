import React from "react"

type I18nState = {
  lang: string
  doneAt: number
  pending: boolean
}

export type I18nContextType = {
  lang: string
  rtl: boolean
  t: (k: string) => string
  changeLang: (k: string) => void
}

export type I18nMessages = Record<string, string>

export type I18nProviderProps = {
  children: React.ReactNode
  fallback: React.ReactNode
  initialLang: string
  getMessages: (lang: string) => Promise<I18nMessages>
}

export const I18nContext = React.createContext<I18nContextType | undefined>(
  undefined
)

function I18nProvider(props: I18nProviderProps) {
  const { children, fallback, initialLang, getMessages } = props

  const [i18nState, setI18nState] = React.useState<I18nState>(() => ({
    lang: initialLang,
    doneAt: 0,
    pending: false,
  }))

  const lang = i18nState.lang

  const messagesRef = React.useRef<I18nMessages>({})

  const changeLang = React.useCallback(lang => {
    setI18nState(s => ({ ...s, lang }))
  }, [])

  React.useEffect(() => {
    setI18nState(s => ({ ...s, pending: true }))
    getMessages(lang)
      .then(messages => {
        messagesRef.current = messages
        setI18nState(s => ({ ...s, pending: false, doneAt: Date.now() }))
      })
      .catch(e => {
        setI18nState(s => ({ ...s, pending: false, doneAt: Date.now() }))
      })

    //eslint-disable-next-line
  }, [lang])

  const contextValue = React.useMemo(() => {
    return {
      changeLang,
      lang,
      rtl: lang === "ar",
      t: (k: string) => messagesRef.current[k] || k,
    }
  }, [changeLang, lang])

  //eslint-disable-next-line
  return (
    <I18nContext.Provider value={contextValue}>
      {i18nState.pending ? fallback : children}
    </I18nContext.Provider>
  )
}

export function useI18n() {
  const i18n = React.useContext(I18nContext)
  if (!i18n) {
    throw new Error("useI18n hook must be used inside i18nProvider!")
  }
  return i18n
}

export default I18nProvider
