import React, { SyntheticEvent, ReactNode } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    snack: {
      maxWidth: "240px",
    },
  })
)

export type SnackbarsProps = {
  children: ReactNode
}

export type SnackbarMessage = {
  message: string
  key?: number
  status?: string
}

export type SnackbarState = {
  open: boolean
  messageInfo?: SnackbarMessage
}

export type SnackbarContextType = {
  notify: (m: SnackbarMessage) => void
}

const initCtx: SnackbarContextType = {
  notify: _m => {},
}

export const SnackbarContext = React.createContext<SnackbarContextType>(initCtx)

function Snackbars(props: SnackbarsProps) {
  const queueRef = React.useRef<SnackbarMessage[]>([])
  const [open, setOpen] = React.useState(false)
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined)

  const processQueue = React.useCallback(() => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift())
      setOpen(true)
    }
  }, [queueRef])

  const notify = React.useCallback(
    ({ message, status }: SnackbarMessage) => {
      queueRef.current.push({
        message,
        key: new Date().getTime(),
        status,
      })

      if (open) {
        // immediately begin dismissing current message
        // to start showing new one
        setOpen(false)
      } else {
        processQueue()
      }
    },
    [queueRef, processQueue, open]
  )

  const handleClose = (
    _event: SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const handleExited = () => {
    processQueue()
  }

  const classes = useStyles()

  const contextValue = React.useMemo(() => ({ notify }), [notify])

  return (
    <React.Fragment>
      <Snackbar
        className={classes.snack}
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        onExited={handleExited}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            {messageInfo ? messageInfo.message : undefined}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <SnackbarContext.Provider value={contextValue}>
        {props.children}
      </SnackbarContext.Provider>
    </React.Fragment>
  )
}

export function useSnackbars() {
  let c = React.useContext(SnackbarContext)
  return c
}

export default Snackbars
