import { authorised, publik } from "./clients"
import { UserDetails, ApiOperation } from "types"

type UsernameParam = {
  username: string
}

// get user details

type GetUserDetails = ApiOperation<UsernameParam, UserDetails>

export const getUserDetails: GetUserDetails = ({ username }) =>
  authorised.get(`users/${username}/details`).json()

// Change Password

type ChangePasswordPayload = {
  existingPassword: string
  newPassword: string
}

export const changePassword: ApiOperation<
  ChangePasswordPayload,
  unknown
> = pld => authorised.post(`users/change-password`, { json: pld }).json()

// Reset Password

type ResetPasswordPayload = {
  token: string
  password: string
}

export const resetPassword: ApiOperation<ResetPasswordPayload, unknown> = pld =>
  publik.post(`users/reset-password`, { json: pld }).json()

//  user forgot password

type ForgotPasswordPayload = UsernameParam

export const forgotPassword: ApiOperation<ForgotPasswordPayload, unknown> = ({
  username,
}) =>
  publik
    .post(`users/forgot-password`, { json: { username, origin: "APP" } })
    .json()
