import decodeToken from "jwt-decode"

export const saveToken = (key = "accessToken", value: string) => {
  localStorage.setItem(key, value)
}

export const clearToken = (key = "accessToken") => {
  localStorage.removeItem(key)
}

export const clearTokens = (
  keys: [string, string] = ["accessToken", "refreshToken"]
) => {
  keys.forEach(clearToken)
}

export const getToken = (key = "accessToken") =>
  localStorage.getItem(key) || undefined

export const isTokenExpired = (token?: string): boolean => {
  if (!token) return true
  try {
    const tk: any = decodeToken(token)
    const expiration = +new Date(tk.exp * 1e3)
    return Date.now() > expiration
  } catch (_e) {
    return true
  }
}

export function extractTokenInfo<T>(token?: string) {
  if (!token) return null
  const decoded = decodeToken<T>(token)
  return decoded
}
