import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

import Loading from "components/Loading"
import NotFound from "components/NotFound"
import Layout from "./Layout"
// routes
const Login = lazy(() => import("./Login"))
const Forgot = lazy(() => import("./Forgot"))
const Reset = lazy(() => import("./Reset"))

function AuthRoutes() {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/forgot" component={Forgot} />
          <Route path="/auth/reset" component={Reset} />

          <Route path="/auth" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Layout>
  )
}

export default AuthRoutes
