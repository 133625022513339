import React from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom"
import Link, { LinkProps } from "@material-ui/core/Link"
import Button, { ButtonProps } from "@material-ui/core/Button"
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton"
import Avatar, { AvatarProps } from "@material-ui/core/Avatar"

import Fab, { FabProps } from "@material-ui/core/Fab"

const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
)

export function ButtonLink(props: RouterLinkProps & ButtonProps) {
  return <Button component={AdapterLink} {...props} />
}

export function IconButtonLink(props: RouterLinkProps & IconButtonProps) {
  return <IconButton component={AdapterLink} {...props} />
}

export function FabLink(props: RouterLinkProps & FabProps) {
  return <Fab component={AdapterLink} {...props} />
}

export function TextLink(props: RouterLinkProps & LinkProps) {
  return <Link component={AdapterLink} {...props} />
}

export function AvatarLink(props: RouterLinkProps & AvatarProps) {
  return <Avatar component={AdapterLink} {...props} />
}
