import React, { ReactNode } from "react"
import { css } from "emotion"
import { useLocation } from "react-router-dom"
import { useUser } from "../User"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

// import MenuIcon from "@material-ui/icons/Menu"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { IconButtonLink, AvatarLink } from "components/Links"

import { LayoutContextProvider, useDocumentTitle } from "./LayoutContext"

const cns = {
  root: css`
    label: base-root;
    display: flex;
    flex-direction: column;
  `,
  menuButton: css`
    margin-right: 1rem;
  `,
  title: css`
    flex-grow: 1;
  `,
  avatar: css`
    // width: 24px;
    // height: 24px;
    text-decoration: none;
    text-transform: uppercase;
  `,
  content: css`
    label: base-content;
    display: flex;
    flex-grow: 1;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding-top: 56px;
    @media (min-width: 600px) {
      padding-top: 64px;
    }
  `,
}

type LayoutProps = {
  children: ReactNode
}

function Layout(props: LayoutProps) {
  const { children } = props
  const { pathname } = useLocation()
  const { details } = useUser()

  const doctitle = useDocumentTitle()

  const title = doctitle.pageTitle || document.title
  const back = pathname === "/" ? undefined : "/"

  const userInitialLetter = details?.username[0] ?? "X"

  return (
    <div className={cns.root}>
      <AppBar position="fixed">
        <Toolbar>
          {back ? (
            <IconButtonLink
              edge="start"
              className={cns.menuButton}
              color="inherit"
              aria-label="back"
              to={back}
            >
              <ArrowBackIcon />
            </IconButtonLink>
          ) : null}
          <Typography variant="h6" className={cns.title}>
            {title}
          </Typography>

          <AvatarLink to="/profile-settings" className={cns.avatar}>
            {userInitialLetter}
          </AvatarLink>
        </Toolbar>
      </AppBar>

      <LayoutContextProvider value={doctitle}>
        <div className={cns.content}>{children}</div>
      </LayoutContextProvider>
    </div>
  )
}

export default Layout
