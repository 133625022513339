import React from "react"
import { Router } from "react-router-dom"
import ky from "ky"
import { ReactQueryConfigProvider } from "react-query"

import { history } from "oh-my/history"

import Snackbars from "snackbars"

import I18nProvider from "I18n"
import StylesAndThemeProvider from "oh-my/theme"

import Loading from "components/Loading"

import Routes from "./routes"

const queryConfig = {
  refetchAllOnWindowFocus: false,
  retry: 1,
}

let getMessages = (lang: string): Promise<Record<string, string>> =>
  ky.get(`${process.env.PUBLIC_URL}/messages/${lang}.json`).json()

function App() {
  return (
    <Router history={history}>
      <StylesAndThemeProvider>
        <ReactQueryConfigProvider config={queryConfig}>
          <I18nProvider
            getMessages={getMessages}
            fallback={<Loading />}
            initialLang="en"
          >
            <Snackbars>
              <Routes />
            </Snackbars>
          </I18nProvider>
        </ReactQueryConfigProvider>
      </StylesAndThemeProvider>
    </Router>
  )
}

export default App
