import React from "react"

const MAIN_TITLE = "ARCL"

export function useDocumentTitle(mainTitle?: string) {
  const [pageTitle, $pageTitle] = React.useState(() => "")
  const main = mainTitle || MAIN_TITLE
  React.useEffect(() => {
    const x = pageTitle ? `${pageTitle} | ${main}` : main
    document.title = x

    return () => {
      document.title = MAIN_TITLE
    }
  }, [pageTitle, main])

  const setPageTitle = React.useCallback(
    (title: string) => {
      $pageTitle(title)
    },
    [$pageTitle]
  )

  return {
    pageTitle,
    setPageTitle,
  }
}

export type LayoutContextType = {
  setPageTitle: (title: string) => void
  pageTitle: string
}

const LayoutContext = React.createContext<LayoutContextType | undefined>(
  undefined
)

export const LayoutContextProvider = LayoutContext.Provider

export function useLayoutContext() {
  const c = React.useContext(LayoutContext)
  if (!c) {
    throw new Error(
      "useLayoutContext must be used inside LayoutContextProvider!"
    )
  }
  return c
}

export function usePageTitle(pageTitle: string) {
  const c = React.useContext(LayoutContext)
  if (!c) {
    throw new Error("usePageTitle must be used inside LayoutContextProvider!")
  }
  let { setPageTitle } = c
  React.useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  return null
}
