import React from "react"
import { Switch, Route } from "react-router-dom"

// routes
import Auth from "./Auth"
import Base from "./Base"

function Routes() {
  return (
    <Switch>
      <Route path="/auth" component={Auth} />
      <Route component={Base} />
    </Switch>
  )
}

export default Routes
