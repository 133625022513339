import { LoginValues, ToauthResponse } from "types"
import { publik } from "./clients"

/*
access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjkxNTk1NDIsInVzZXJfbmFtZSI6Im9ic2VydmVyIiwianRpIjoiMzY3ZTJkMDYtY2E3Yy00NTkxLWJmZDktOTBjMWIzM2U0NGE0IiwiY2xpZW50X2lkIjoic2FmY28tcmVwb3J0cy1hcHAiLCJzY29wZSI6WyJ0cnVzdCJdfQ.9_YXc4umRB8QOForEB2QIW5-ZUswb-iQ4ExNKDgZMq8"
expires_in: 3599
jti: "367e2d06-ca7c-4591-bfd9-90c1b33e44a4"
refresh_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjkyNDIzNDIsInVzZXJfbmFtZSI6Im9ic2VydmVyIiwianRpIjoiMmI1ZmI5YzctNjM1NS00ZjJkLWFiNmUtNTVlYjBmMzcwMGFlIiwiY2xpZW50X2lkIjoic2FmY28tcmVwb3J0cy1hcHAiLCJzY29wZSI6WyJ0cnVzdCJdLCJhdGkiOiIzNjdlMmQwNi1jYTdjLTQ1OTEtYmZkOS05MGMxYjMzZTQ0YTQifQ.mdYqJB6ekeKkuuApsg3PJOCg83UjNlY2oK-1Z4Z-9dI"
scope: "trust"
token_type: "bearer"
*/

function makeTokensResponse(res: any) {
  const { access_token, refresh_token, ...rest } = res as ToauthResponse
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    meta: rest,
  }
}

export const getTokens = (values: LoginValues) => {
  const searchParams = new URLSearchParams()
  searchParams.set("grant_type", "password")
  searchParams.set("username", values.username)
  searchParams.set("password", values.password)

  return publik
    .post("oauth/token", {
      body: searchParams,
      headers: {
        Authorization: "Basic c2FmY28tcmVwb3J0cy1hcHA6czRmYzAtcjNwMHJ0cy00cHA=",
      },
    })
    .json()
    .then(makeTokensResponse)
}

export const refreshTokens = (refreshToken: string) => {
  const searchParams = new URLSearchParams()
  searchParams.set("grant_type", "refresh_token")
  searchParams.set("refresh_token", refreshToken)
  return publik
    .post("oauth/token", {
      body: searchParams,
      headers: {
        Authorization: "Basic c2FmY28tcmVwb3J0cy1hcHA6czRmYzAtcjNwMHJ0cy00cHA=",
      },
    })
    .json()
    .then(makeTokensResponse)
}

// refreshTokens
