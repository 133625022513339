import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

import Authenticated from "Authenticated"
import Loading from "components/Loading"
import NotFound from "components/NotFound"

import Layout from "./Layout"
import User from "./User"

const ReportSubmissionSuccess = lazy(() => import("./ReportSubmissionSuccess"))
const ProfileSettings = lazy(() => import("./ProfileSettings"))
const Questionnaires = lazy(() => import("./Questionnaires"))
const DraftsList = lazy(() => import("./Drafts"))
const Report = lazy(() => import("./Report"))

const Base = () => {
  return (
    <Authenticated>
      <User>
        <Layout>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/profile-settings" component={ProfileSettings} />
              <Route path="/qnr" component={Questionnaires} />
              <Route path="/report/:slug/:id?" component={Report} />
              <Route
                path="/report-submission-success"
                component={ReportSubmissionSuccess}
              />

              <Route path="/" component={DraftsList} />

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </User>
    </Authenticated>
  )
}

export default Base
