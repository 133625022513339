import { createMuiTheme } from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"

//eslint-disable-next-line
// const defaultTheme = createMuiTheme()

const custom = {
  palette: {
    primary: {
      main: grey[900],
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      subtitle2: {
        color: "var(--text_2)",
      },
    },
  },
}

export const theme = createMuiTheme(custom)
