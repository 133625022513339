import React from "react"

import { css } from "emotion"
import { Typography } from "@material-ui/core"

let cns = {
  root: css`
    label: auth-layout;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin: 2rem auto;
  `,
  container: css`
    label: auth-layout-container;
    display: flex;
    justify-content: center;
    width: 90%;
    max-width: 600px;
    margin: 2.5rem 1.5rem 1.5rem 1.5rem;

    border: 1px solid #eaeaea;
    padding: 2rem;
    border-radius: 6px;

    @media (max-width: 10rem) {
      width: auto;
      margin: 2.5rem 0.5rem 1.5rem 0.5rem;
    }
  `,
}

function Layout(props: { children: React.ReactNode }) {
  const { children } = props
  return (
    <div className={cns.root}>
      <Typography variant="h5" component="h1" gutterBottom>
        Aviation Refueling Checklists
      </Typography>
      <div className={cns.container}>{children}</div>
    </div>
  )
}

export default Layout
