import React from "react"

import { getUserDetails } from "api/users"

import { UserDetails } from "types"
import { useAuthenticated } from "Authenticated"
import useIsMounted from "hooks/useIsMounted"

type UserProps = {
  children: React.ReactNode
}

type UserAction =
  | {
      type: "AIRPORT"
      payload: string
    }
  | {
      type: "DETAILS"
      payload: UserDetails
    }

type UserState = {
  airport: string
  details: UserDetails | null
  doneAt: number
}

type UserContextType = {
  details: UserDetails | null
  airport: string
  setAirport: (payload: string) => void
}

const UserContext = React.createContext<UserContextType | undefined>(undefined)

export function useUser() {
  const c = React.useContext(UserContext)
  if (!c) {
    throw new Error("useUser must be used inside UserContext!")
  }
  return c
}

const initialState: UserState = {
  airport: "",
  details: null,
  doneAt: 0,
}

function reducer(s: UserState, action: UserAction): UserState {
  switch (action.type) {
    case "DETAILS": {
      const { payload } = action
      const airports = payload.airports
      const first = airports[0].code
      return { ...s, details: payload, airport: first, doneAt: Date.now() }
    }
    case "AIRPORT": {
      const { payload } = action
      return { ...s, airport: payload }
    }
    default: {
      console.error(`Store:: no action `)
      return s
    }
  }
}

function User(props: UserProps) {
  const username = useAuthenticated()
  const isMounted = useIsMounted()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { doneAt, airport, details } = state
  React.useEffect(() => {
    if (!username) return
    // if (!isMounted) return
    getUserDetails({ username })
      .then(res => {
        if (!isMounted.current) return
        // console.log("getUserDetails::res")
        dispatch({ type: "DETAILS", payload: res })
      })
      .catch(errors => {
        console.log("failed to fetch user details", errors)
      })
  }, [username, isMounted])

  const contextValue = React.useMemo(() => {
    return {
      details,
      airport,
      setAirport: (payload: string) => dispatch({ type: "AIRPORT", payload }),
    }
    // eslint-disable-next-line
  }, [doneAt, airport])

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  )
}

export default User
