import React from "react"
import { a12n } from "oh-my/a12n"
import { useHistory } from "react-router-dom"
import useIsMounted from "hooks/useIsMounted"

type AuthenticatedContextType = string

const AuthenticatedContext = React.createContext<
  AuthenticatedContextType | undefined
>(undefined)

export function useAuthenticated(): string {
  const c = React.useContext(AuthenticatedContext)
  if (c === undefined) {
    throw new Error("useAuthenticated must be used inside Authenticated!")
  }
  return c
}

type AuthenticatedProps = {
  children: React.ReactNode
}

const authenticationPath = "/auth"

function Authenticated(props: AuthenticatedProps) {
  const { children } = props
  const h = useHistory()
  const isMounted = useIsMounted()
  const [username, setUsername] = React.useState<string>(() => "")

  React.useEffect(() => {
    let unsubscribe = a12n.onAuthStateChanged(authState => {
      if (authState.pending) return
      if (authState.user === undefined) {
        return
      } else if (authState.user === null) {
        h.push(authenticationPath)
      } else {
        if (isMounted.current) {
          setUsername(authState.user.user_name)
        }
      }
    })

    return () => {
      unsubscribe()
    }

    //eslint-disable-next-line
  }, [isMounted])

  return (
    <AuthenticatedContext.Provider value={username}>
      {children}
    </AuthenticatedContext.Provider>
  )
}

export default Authenticated
