import React from "react"
import { css } from "emotion"
import CircularProgress from "@material-ui/core/CircularProgress"

const cn = css`
  padding: 2rem 1rem 3rem;

  display: flex;
  align-items: center;
  justify-content: center;
`

function Loading() {
  return (
    <div className={cn}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default Loading
