import React from "react"
import { create } from "jss"
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles"

import { theme } from "./theme"
export { theme } from "./theme"

const jss = create({
  plugins: [...jssPreset().plugins],
  insertionPoint: document.getElementById("jss-insertion-point")!!,
})

type StylesConfigProps = {
  children: React.ReactNode
}

function StylesAndTheme(props: StylesConfigProps) {
  const { children } = props

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  )
}

export default StylesAndTheme
