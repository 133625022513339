import ky, { AfterResponseHook } from "ky"
import { a12n } from "oh-my/a12n"

import { parseErrorResponse } from "./utils"

type AfterResponseHooks = Record<string, AfterResponseHook>
const afterResponseHooks: AfterResponseHooks = {
  handleEmptyResponse: (_i, _o, response) => {
    if (response.status === 204 || response.status === 202) {
      const o = JSON.stringify({})
      return new Response(o, { status: 200 })
    } else {
      return response
    }
  },
  handleUnauthorised: (_input, _options, response) => {
    if (response.status === 401) {
      a12n.on401()
      return
    }
    if (response.status === 403) {
      a12n.on403()
      return
    }
    return response
  },
  handleErrors: async (_input, _options, response) => {
    if (!response.ok) {
      let parsedError = await parseErrorResponse(response)
      throw parsedError
    } else {
      return response.clone()
    }
  },
}

export const base = ky.extend({
  throwHttpErrors: true,
  retry: 0,
  prefixUrl: "/api",
  hooks: {
    afterResponse: [afterResponseHooks.handleEmptyResponse],
  },
})

export const publik = base.extend({
  hooks: {
    afterResponse: [afterResponseHooks.handleErrors],
  },
})

export const authorised = base.extend({
  // throwHttpErrors: false,
  hooks: {
    beforeRequest: [
      async request => {
        const accessToken = await a12n.getAccessToken()
        if (accessToken) {
          request.headers.set("Authorization", "Bearer " + accessToken)
        }
      },
    ],
    afterResponse: [
      afterResponseHooks.handleUnauthorised,
      afterResponseHooks.handleErrors,
    ],
  },
})
